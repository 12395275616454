<template>
  <b-modal
    id="paymentModal"
    ref="paymentModal"
    centered
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
  >
    <template #modal-title>
      <h5 class="payment-modal-title">
        Payment options
      </h5>
    </template>
    <b-form>
      <b-form-group class="mb-0">
        <b-form-group
          id="paymentForm"
          label="Choose your payment method"
        >
          <b-form-radio
            v-model="paymentMethod"
            name="payment-option"
            value="stripe"
          >
            <img src="@/assets/images/svg/stripe.svg">
          </b-form-radio>
          <b-dropdown-divider />
          <b-form-radio
            v-model="paymentMethod"
            disabled
            name="payment-option"
            value="wire"
          >
            <b class="wire-text">Wire transfer</b><br>
            <img src="@/assets/images/icons/payment.png">
          </b-form-radio>
          <b-dropdown-divider />
          <b-form-radio
            v-model="paymentMethod"
            name="payment-option"
            value="pay_later"
          >
            <b class="wire-text">Buy now, pay later</b><br>
            <img src="@/assets/images/icons/payment.png">
          </b-form-radio>
        </b-form-group>
      </b-form-group>
      <div class="justify-content-center row">
        <b-button
          id="cancel-payment"
          class="mt-3 col-4"
          variant="outline-info"
          block
          @click="hideModal"
        >Cancel</b-button>
        <b-button
          id="proceed"
          variant="none"
          class="mt-3 col-4"
          block
          type="submit"
          :disabled="!paymentMethod || loading"
          @click="stripePayment"
        >Proceed</b-button>
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import {
  BForm,
  BFormGroup,
  BButton,
  BModal,
  BFormRadio,
  BDropdownDivider,
} from 'bootstrap-vue'
import { loadStripe } from '@stripe/stripe-js'
import { apiToastError } from '@/@core/utils/toast'
import { capitalize } from '@/@core/utils/filter'
import { PAY_TRANSACTION } from '@/store/modules/wallet.module'
import { Settings } from '@/Settings'

const VUE_APP_STRIPE_PK = Settings.getConfig('VUE_APP_STRIPE_PK')

const stripePromise = loadStripe(VUE_APP_STRIPE_PK)

export default {
  name: 'PaymentModal',
  components: {
    BForm,
    BFormGroup,
    BButton,
    BModal,
    BFormRadio,
    BDropdownDivider,
  },
  props: {
    transaction: {
      type: String,
      default() {
        return ''
      },
    },
    callBack: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      paymentMethod: '',
      loading: false,
    }
  },
  methods: {
    hideModal() {
      this.paymentMethod = ''
      this.$refs.paymentModal.hide()
    },
    async stripePayment(e) {
      e.preventDefault()
      this.loading = true
      try {
        const stripe = await stripePromise
        const { data } = await this.$store.dispatch(PAY_TRANSACTION,
          {
            transaction: this.transaction,
            payload: {
              successPage: this.callBack,
              cancelPage: this.callBack,
              method: this.paymentMethod,
            },
          })

        const result = await stripe.redirectToCheckout({
          sessionId: data.data.id,
        })
        if (result.error) apiToastError(capitalize(result.error.message))
      } catch ({ message, response }) {
        apiToastError(capitalize(response?.data.message || message))
      }
      this.hideModal()
      this.loading = false
    },
  },
}
</script>
<style lang="scss">
@import 'src/@core/scss/base/bootstrap-extended/_variables.scss';

#cancel-payment {
  margin-right: 1em;
  font-weight: 600;
}
#paymentModal {
  .modal-body {
    padding-left: 2em;
  }
  .payment-modal-title {
    font-size: large;
    color: $black;
    font-weight: bolder;
  }
}
#proceed {
  background-color: $deep-info;
  color: white;
}
#paymentForm {
  li[role='presentation'] {
    display: block;
    margin-top: 1.4em;
    margin-bottom: 3em;
  }
  legend {
    font-size: large;
    font-weight: 700;
    margin-bottom: 2em;
    color: $black;
  }
  .custom-control-label {
    margin-top: -1.5em;
    margin-left: 1em;
  }

  .dropdown-divider {
    border-top: 1.7px solid $gray-600;
  }

  .wire-text {
    font-size: 1.4em;
  }
}

.payment-option {
  width: 85%;
  margin-left: auto;
  span {
    font-weight: bold;
  }
}
</style>
