<template>
  <b-card
    no-body
    class="mb-0 border-0"
  >
    <div
      v-if="!isLoaded"
      class="text-center p-5"
    >
      <b-spinner />
    </div>
    <template v-else>
      <div class="py-2 pr-1 pl-1 d-flex justify-content-between transaction-btn-section">
        <div class="d-md-flex flex-md-row">
          <div class="ml-1 pb-2">
            <b-form-group class="select-input-class">
              <h5>Range</h5>
              <flat-pickr
                ref="range"
                v-model="filterDate"
                class="form-control"
                placeholder="Select"
                :config="{ dateFormat: KP_DATE_FORMAT.CONFIG, mode: 'range' }"
                @on-change="onSelectRange"
              />
            </b-form-group>
          </div>
          <div class="ml-1">
            <b-form-group class="amount-due-odue-input">
              <h5>Amount Due</h5>
              <b-form-input
                id="amount-due"
                v-model="amountDue"
                name="amountDue"
                placeholder="Amount Due"
                readonly
              />
            </b-form-group>
          </div>
          <div class="ml-1">
            <b-form-group class="amount-due-odue-input">
              <h5>Amount Over Due</h5>
              <b-form-input
                id="amount-over-due"
                v-model="amountOverDue"
                name="amountOverDue"
                placeholder="Amount Over Due"
                readonly
              />
            </b-form-group>
          </div>
          <div
            class="ml-1"
          >
            <b-form-group class="available-limit-input">
              <h5>Available limit</h5>
              <b-form-input
                :value="dataToCurrency(availableCreditLimit)"
                name="availableCreditLimit"
                placeholder="Available limit"
                readonly
                disabled
              />
            </b-form-group>
          </div>
          <div class="align-self-center ml-1 mt-1">
            <b-dropdown
              variant="none"
              class="select-input-class dropdown-border"
              no-caret
            >
              <template #button-content>
                <div class="d-flex justify-content-between">
                  <span class="pr-2-px font-size-16-px"> {{ searchingText || 'All Orders' }}</span>
                  <feather-icon
                    class="align-middle"
                    icon="ChevronDownIcon"
                    size="16"
                  />
                </div>
              </template>
              <b-dropdown-form>
                <b-form-input
                  v-model="searchingText"
                  placeholder="Order ID"
                  @input="onSearch"
                />
              </b-dropdown-form>
              <b-dropdown-divider />
              <b-dropdown-item
                :active="!searchingText"
                @click="onSearch('')"
              >
                All Orders
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div
            v-if="filterDate || searchingText"
            class="align-self-center ml-2 mt-1"
          >
            <b-link
              class="color-blue"
              @click="clearFilters"
            >
              Clear filters
            </b-link>
          </div>
        </div>
        <div class="ml-1 mt-1">
          <div class="align-self-center float-right">
            <b-button
              class="float-right mt-1"
              variant="outline-primary"
              @click="downloadTransactionList"
            >
              <download-icon-2 class="mr-50" />
              Export Transactions
            </b-button>
          </div>
        </div>
      </div>
      <b-table
        v-if="isLoaded"
        id="transaction_table_list"
        ref="refTransactionListTable"
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :items="transactions"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        show-empty
        empty-text="No transactions found"
      >
        <!-- Column: Payment -->
        <template #cell(payment)="data">
          <b-media
            v-if="isPayEnabled(data.item.type, data.item.amount)"
            vertical-align="center"
          >
            <b-button
              v-b-modal.paymentModal
              class="float-right py-50 px-1"
              variant="secondary-black"
              pill
              :disabled="data.item.status === 'paid'"
              @click="() => setModalProp(data.item._id)"
            >
              Pay
            </b-button>
          </b-media>
        </template>
        <!--- Column: Transaction ID -->
        <template #cell(transactionId)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item._id.substring(data.item._id.length - 4) }}
            </span>
          </b-media>
        </template>

        <!-- Column: Transaction Date -->
        <template #cell(transactionDate)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="ClockIcon"
              size="18"
              class="mr-50 text-info"
            />
            <span class="align-text-top text-capitalize">{{
              getDateFromTimestamp(data.item.transactionDate)
            }}</span>
          </div>
        </template>
        <!--- Column: Order ID -->
        <template #cell(orderSeqId)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.orderSeqId }}
            </span>
          </b-media>
        </template>
        <!--- Column: Shipment ID -->
        <template #cell(shipmentSeqId)="data">
          <b-media vertical-align="center">
            <span
              v-if="data.item.shipmentSeqId"
              class="font-weight-bold d-block text-nowrap"
            >
              <!-- use eye icon when shipmentId is present -->
              {{ data.item.shipmentSeqId }}
            </span>
          </b-media>
        </template>
        <template #cell(invoiceNumber)="data">
          <b-media vertical-align="center">
            <span
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.invoiceNumber }}
            </span>
          </b-media>
        </template>
        <!-- Column: Amount -->
        <template #cell(amount)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ formatCurrency(data.item.amount, data.item.currency) }}
              <span
                v-if="data.item.discountInCredit"
                v-b-tooltip.hover.bottom="`${data.item.discountInCredit} Credit Points were applied to this invoice`"
                class="color-dark-blue cursor-pointer font-small-4"
              >
                CP
              </span>
            </span>
          </b-media>
        </template>
        <!-- Column: Amount Paid -->
        <template #cell(amountPaid)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ formatCurrency((data.item.amountPaid || 0), data.item.currency) }}
            </span>
          </b-media>
        </template>
        <!-- Column: Amount With VAT -->
        <template #cell(amountWithVat)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.amountWithVat ? formatCurrency ((data.item.amountWithVat), data.item.currency) : formatCurrency((data.item.amount), data.item.currency) }}
            </span>
          </b-media>
        </template>
        <!-- Column: Amount With VAT -->
        <template #cell(amountDue)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ formatCurrency((data.item.amountDue || 0), data.item.currency) }}
            </span>
          </b-media>
        </template>
        <!-- Column: Total Units -->
        <template #cell(totalQuantity)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ formatNumber(data.item.totalQuantity) || '' }}
            </span>
          </b-media>
        </template>
        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>
        <!-- Column: Due Date -->
        <template #cell(dueDate)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="ClockIcon"
              size="18"
              class="mr-50 text-info"
            />
            <span class="align-text-top text-capitalize">{{
              getDateFromTimestamp(data.item.dueDate)
            }}</span>
          </div>
        </template>
        <!-- Column: Payment Method -->
        <template #cell(paymentMethod)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ PAYMENT_METHODS_TEXT[data.item.paymentMethod] }}
            </span>
          </b-media>
        </template>
      </b-table>
      <div
        v-if="totalTransactions"
        class="mx-2 mb-2 mt-auto"
      >
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTransactions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </template>
    <payment-modal
      :transaction="currentTransaction"
      :call-back="'/transactions'"
    />
  </b-card>
</template>

<script>
import {
  BLink,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BBadge,
  BPagination,
  BSpinner,
  VBModal,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BDropdownForm,
} from 'bootstrap-vue'
import { constants as c, utils } from '@kingpin-global/kingpin-utils-frontend'

import flatPickr from 'vue-flatpickr-component'
import { formatNumber } from '@/@core/utils/utils'
import {
  parsePaymentStatus,
  dataToCurrency,
  getDateFromTimestamp,
  filterDataByDate,
  formattedString,
  objectFlip,
} from '@/common-utils'
import analytics from '@/@core/utils/analytics'
import constants, { FILE_FORMATS, TRANSACTION_PAY_LIMIT } from '@/constants'
import PaymentModal from '@/views/components/modal/PaymentModal.vue'
import { kpEndpoint } from '@/axios'
import store from '@/store'
import { DOWNLOAD_FILE } from '@/store/modules/common.module'
import { GET_TRANSACTIONS } from '@/store/modules/wallet.module'
import { apiToastError } from '@/@core/utils/toast'
import { GET_TRANSACTION_TABLE_COLUMNS } from '@/table-columns-constants'
import DownloadIcon2 from '@/@core/assets/svg-components/DownloadIcon2.vue'

const { formatCurrency } = utils

const { KP_DATE_FORMAT, TRANSACTION_TYPE } = c

export default {
  name: 'TransactionList',
  components: {
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BBadge,
    BPagination,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BDropdownForm,
    flatPickr,
    'payment-modal': PaymentModal,
    DownloadIcon2,
},
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  setup() {
    return {
      KP_DATE_FORMAT,
    }
  },
  data() {
    return {
      filterDate: null,
      isLoaded: false,
      transactions: [],
      transactionsLocal: [],
      currentPage: 1,
      perPage: 10,
      totalTransactions: 0,
      amountDue: 0,
      amountOverDue: 0,
      availableCreditLimit: 0,
      tableColumns: GET_TRANSACTION_TABLE_COLUMNS(),
      parsePaymentStatus,
      dataToCurrency,
      getDateFromTimestamp,
      formatNumber,
      filterDataByDate,
      formatCurrency,
      formattedString,
      constants,
      currentTransaction: '',
      searchingText: '',
    }
  },
  computed: {
    isRetailer() {
      return this.$store.getters.isRetailer
    },
    isBrand() {
      return this.$store.getters.isBrand
    },
    PAYMENT_METHODS_TEXT() {
      return objectFlip(constants.PAYMENT_METHODS, formattedString)
    },
  },
  created() {
    this.loadTransactions()
    analytics.track(this.isBrand ? constants.TRACKS.PAGES.BRAND_OPENS_TRANSACTIONS : constants.TRACKS.PAGES.RETAILER_OPENS_TRANSACTIONS)
  },
  methods: {
    setModalProp(transactionId) {
      this.currentTransaction = transactionId
    },
    onSelectRange(dates) {
      this.transactions = filterDataByDate(
        this.transactionsLocal,
        dates[0],
        dates[1],
      )
      this.totalTransactions = this.transactions.length
    },
    loadTransactions(searchText = '') {
      const queryParams = {}
      if (searchText) {
          queryParams.orderSeqId = searchText
      }
      this.$store.dispatch(GET_TRANSACTIONS, queryParams).then(res => {
        this.setResponse(res)
      }).catch(err => {
        apiToastError(err)
      })
    },
    setResponse(res) {
        this.transactions = res.data.data.transactions
        this.transactionsLocal = res.data.data.transactions
        this.amountDue = dataToCurrency(res.data.data.amountDue)
        this.amountOverDue = dataToCurrency(res.data.data.amountOverDue)
        this.availableCreditLimit = Math.max(res.data.data.availableCreditLimit, 0)
        this.totalTransactions = this.transactions.length
        this.isLoaded = true
        this.$emit('transactions-loaded')
    },
    clearFilters() {
      this.$refs.range.$el._flatpickr.clear()
      this.searchingText = ''
      this.loadTransactions()
    },
    onSearch(searchingText = '') {
      this.searchingText = searchingText
      if (searchingText) {
        this.loadTransactions(searchingText)
      } else {
        this.loadTransactions()
      }
    },
    downloadTransactionList() {
      const payload = {
        url: kpEndpoint.transaction.download,
        fileName: 'transactions.csv',
        successMessage: 'Your transaction export is downloading...',
        fileFormat: FILE_FORMATS.CSV,
      }
      store.dispatch(DOWNLOAD_FILE, payload)
    },
    isPayEnabled(transactionType, amount) {
      return ![TRANSACTION_TYPE.CREDIT_NOTE, TRANSACTION_TYPE.DEBIT_NOTE].includes(transactionType) && amount < TRANSACTION_PAY_LIMIT
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';

.transaction-btn-section {
.select-input-class {
  height: 2.714rem;
  min-width: 220px;

  &.dropdown-border {
    border: 1px solid $gray-402 !important;
  }
          .dropdown-item {
            padding-left: 2rem;
            color: $actions-text-grey;
            &:hover{
                background-color: rgba($info, 0.1);
                color: $actions-text-grey;
            }
            &.active {
                background-color: $info;
                color: $white;
            }
        }
}
.amount-due-odue-input {
  max-width: 140px;
}
}

</style>
