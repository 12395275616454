import store from './store'

// Transaction table fields
export const GET_TRANSACTION_TABLE_COLUMNS = () => [
    store.getters.isRetailer
        ? {
            key: 'payment',
            label: 'Payment',
        }
        : {},
    {
        key: 'transactionId',
        label: 'Transaction ID',
    },
    {
        key: 'transactionDate',
        label: 'Transaction Date',
        sortable: true,
    },
    {
        key: 'invoiceNumber',
        label: 'Invoice Number',
    },
    {
        key: 'orderSeqId',
        label: 'Order ID',
    },
    {
        key: 'shipmentSeqId',
        label: 'Shipment ID',
    },
    {
        key: 'amount',
        label: 'Invoice Amount',
    },
    {
        key: 'amountWithVat',
        label: 'Amount with VAT',
    },
    {
        key: 'amountPaid',
        label: 'Amount Paid',
    },
    {
        key: 'amountDue',
        label: 'Amount Due',
    },
    {
        key: 'totalQuantity',
        label: 'Total Units',
    },
    {
        key: 'status',
        label: 'Status',
        sortable: true,
    },
    {
        key: 'dueDate',
        label: 'Due Date',
    },
    {
        key: 'paymentMethod',
        label: 'Payment Method',
    },
]

// Order table fields
export const GET_ORDER_TABLE_COLUMNS = () => {
    const tableColumns = [
        {
            key: 'orderSeqId',
            label: 'Order ID',
        },
        {
            key: 'createdAt',
            label: 'Order Date',
        },
        {
            key: 'time',
            label: 'Time',
        },
    ]
    if (store.getters.isRetailer) {
        tableColumns.push({
            key: 'brandName',
            label: 'Brand',
        })
    } else {
        tableColumns.push({
            key: 'retailerName',
            label: 'Retailer',
        })
    }
    tableColumns.push(
        {
            key: 'collectionName',
            label: 'Collection Name',
        },
        {
            key: 'status',
            label: 'Status',
        },
        {
            key: 'notes',
            label: 'Notes',
        },
        {
            key: 'totalQuantity',
            label: 'Total Units',
        },
        {
            key: 'totalPrice',
            label: 'Total Amount',
        },
    )

    return tableColumns
}

// Shipment table fields
export const GET_SHIPMENT_TABLE_COLUMNS = () => {
    const tableColumns = [
        {
            key: 'shipmentSeqId',
            label: 'Shipment ID',
        },
        {
            key: 'createdAt',
            label: 'Shipment Date',
        },
    ]
    if (store.getters.isRetailer) {
        tableColumns.push({
            key: 'brandName',
            label: 'Brand',
        })
    } else {
        tableColumns.push({
            key: 'retailerName',
            label: 'Reatiler',
        })
    }
    tableColumns.push({
        key: 'status',
        label: 'Status',
    })

    if (store.getters.isRetailer) {
        tableColumns.push({
            key: 'deliveredDate',
            label: 'Delivered Date',
        })
    }
    tableColumns.push(
        {
            key: 'totalQuantity',
            label: 'Total Units',
        },
        {
            key: 'totalPrice',
            label: 'Total Amount',
        },
    )
    return tableColumns
}

export const salesRepField = {
    key: 'salesRepName',
    label: 'Sales representative',
}

// Connection requests - Direct table fields
export const GET_DIRECT_CONNECTIONS_TABLE_COLUMNS = () => [
    {
      key: 'entityName',
      label: 'Entity Name',
    },
    {
      key: 'firstName',
      label: 'First Name',
    },
    {
        key: 'nickName',
        label: 'Nickname',
    },
    {
        key: 'firstOrderDate',
        label: 'First Order Date',
      },
      {
        key: 'lastOrderDate',
        label: 'Last Order Date',
      },
      {
        key: 'country',
        label: 'Country',
      },
      {
        key: 'paymentTerms',
        label: 'Payment Terms',
      },
      {
        key: 'logisticsTerms',
        label: 'Logistics Terms',
      },
      {
        key: 'discount',
        label: 'Discount',
      },
      {
        key: 'socialLinks',
        label: 'Social Links',
      },
      {
        key: 'actions',
        label: '',
      },
]

// Connection requests - Marketplace table fields
export const GET_MARKETPLACE_CONNECTIONS_TABLE_COLUMNS = () => [
    {
      key: 'entityName',
      label: 'Entity Name',
    },
    {
      key: 'firstName',
      label: 'First Name',
    },
    {
        key: 'nickName',
        label: 'Nickname',
    },
    {
        key: 'firstOrderDate',
        label: 'First Order Date',
      },
      {
        key: 'lastOrderDate',
        label: 'Last Order Date',
      },
      {
        key: 'country',
        label: 'Country',
      },
      {
        key: 'socialLinks',
        label: 'Social Links',
      },
  ]

export const GET_REFERRALS_TABLE_COLUMNS = () => ['referralId', 'email', 'createdAt', 'updatedAt', 'status']

export const GET_REWARDS_TABLE_COLUMNS = () => [
    {
        key: 'createdAt',
        label: 'Date',
    },
    {
        key: 'description',
        label: 'Description',
    },
    {
        key: 'amount',
        label: 'Amount',
    },
]

export const GET_SAVINGS_TABLE_COLUMNS = () => [
    {
        key: 'orderSeqId',
        label: 'Order',
    },
    {
        key: 'retailerName',
        label: 'Retailer',
    },
    {
        key: 'createdAt',
        label: 'Date',
    },
    {
        key: 'savings',
        label: 'Amount saved on commission',
    },
]

export const BUYING_SUMMARY_TABLE_FIELDS = () => [
    { key: 'Department', sortable: true },
    { key: 'Category', sortable: true },
    { key: 'SubCategory', sortable: true },
    { key: 'Gender', sortable: true },
    { key: 'OptionCount', sortable: true },
    { key: 'Pieces', sortable: true },
    { key: 'TotalCost', sortable: true },
    { key: 'RRPTotal', sortable: true },
    { key: 'Budget', sortable: false },
    { key: 'Variance%', sortable: false },
  ]
