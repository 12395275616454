<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.14213 9.42426L5.71787 9.84853L5.29361 9.42426L1.69361 5.82426V5.82426C1.45929 5.58995 1.45929 5.21005 1.69361 4.97574V4.97574C1.92792 4.74142 2.30782 4.74142 2.54214 4.97574V4.97574L5.11787 7.55147V0.6V0.5C5.11787 0.223857 5.34173 0 5.61787 0H5.81787C6.09401 0 6.31787 0.223857 6.31787 0.5V0.6V7.55147L8.89361 4.97574V4.97574C9.12792 4.74142 9.50782 4.74142 9.74213 4.97574V4.97574C9.97645 5.21005 9.97645 5.58995 9.74213 5.82426V5.82426L6.14213 9.42426ZM0.817871 10.8C0.541729 10.8 0.317871 11.0239 0.317871 11.3V11.5C0.317871 11.7761 0.541729 12 0.817872 12H10.6179C10.894 12 11.1179 11.7761 11.1179 11.5V11.3C11.1179 11.0239 10.894 10.8 10.6179 10.8H0.817871Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'DownloadIcon2',
}
</script>
